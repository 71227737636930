.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

* {
  /* border: none !important */
  outline: none !important;
}

body {
  margin: 0;
  padding: 0;
}

.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f2f2f2;
}

.auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 400px;
}


.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
}

.navbar {
  /* margin: 0; */
  width: 100%;
  height: 13vh;
  background-color: #4C4F5B;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  text-decoration: none;
}

a {
  color: white;
  text-decoration: none;
  margin: 10px;
  font-size: 25px;
}

.create-player {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 400px;
}
.create-player form {
  display: flex;
  flex-direction: column;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  border: 2px solid black;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}

h2 {
  margin-top: 0;
}

.img-container {
  outline: 2px solid blue;
  display: flex;
  justify-content: center;
  width: 100%;
}

.feed_image {
  width: 500px;
  border-radius: 16px;
  outline: 2px solid purple;
}

p {
  max-width: 400px;
  font-size: 12px;
}

.container-top-part {
  outline: 2px solid red;
  display: flex;
  justify-content: space-between;
}

.logo_container {
  outline: 2px solid red;
  /* height: inherit; */
  /* height: 100%; */
  text-align: center;
  width: 20%;
}

.nfl_logo {
  outline: 2px solid blue;
  width: 20%;
}

.position {
  outline: 2px solid red;
}

.position p {
  outline: 2px solid blue;
  font-size: 1.3rem;
  font-weight: 600;
}

@media (max-width: 850px) {
  .auth {
    flex-direction: column;
    height: auto;
  }

  .feed_image {
    width: 380px;
    border-radius: 16px;
    outline: 2px solid orange;
  }
}
